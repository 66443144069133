<template>
  <div>
    <van-tabs color="rgba(12, 103, 255, 1)" title-active-color="rgba(12, 103, 255, 1)" @click="onClick">
      <van-tab title="投票内容">
        <div style="margin-top:15px;background:#DDDDDD;height: 15px;" />
        <van-cell-group>
          <van-field v-model="formdata.launch_org" label="发起组织" placeholder="请输入发起组织" />
          <van-field v-model="formdata.title" label="投票标题" placeholder="请输入投票标题" />
          <van-field
            readonly
            clickable
            right-icon="arrow-down"
            label="报名开始时间"
            :value="formdata.signup_start"
            placeholder="选择开始时间"
            @click="choosefirst('firststart')"
          />
          <van-field
            readonly
            clickable
            right-icon="arrow-down"
            label="报名结束时间"
            :value="formdata.signup_end"
            placeholder="选择截止时间"
            @click="choosefirst('firstend')"
          />
          <van-field
            readonly
            clickable
            right-icon="arrow-down"
            label="活动开始时间"
            :value="formdata.start_date"
            placeholder="选择开始时间"
            @click="choosefirst('secondstart')"
          />
          <van-field
            readonly
            clickable
            right-icon="arrow-down"
            label="活动结束时间"
            :value="formdata.end_date"
            placeholder="选择开始时间"
            @click="choosefirst('secondend')"
          />
          <div style="padding-top:15px;background:#DDDDDD;" />

          <van-field v-model="formdata.rule" label="活动规则" rows="5" type="textarea" placeholder="请输入活动规则" />
          <van-field v-model="formdata.award_design" label="奖项设计" rows="5" type="textarea" placeholder="请输入奖项设计" />
          <div class="showpic_text">轮播图：</div>
          <div class="picpadding">
            <van-uploader v-model="formdata.banner" :after-read="afterRead" />
          </div>
        </van-cell-group>
        <div class="upbuttom">
          <van-button type="info" block @click="submit">确认提交</van-button>
        </div>
      </van-tab>

      <van-tab title="投票设置">
        <van-cell>
          <template #title>
            <span class="custom-title">投票类型</span>
          </template>
          <template #label>
            <van-radio-group v-model="formdata.vote_config.type" disabled direction="horizontal" class="marginleft100 marginright100 radiobetween">
              <van-radio :name="1">单选</van-radio>
              <van-radio :name="2">多选</van-radio>
            </van-radio-group>
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <span class="custom-title">投票周期</span>
          </template>
          <template #label>
            <van-radio-group v-model="formdata.vote_config.cycle" disabled direction="horizontal" class="marginleft100 marginright100 radiobetween">
              <van-radio :name="1">投完不能再投</van-radio>
              <van-radio :name="2">每天都可以投</van-radio>
            </van-radio-group>
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <span class="custom-title">每个微信号每天可以投多少票</span>
          </template>
          <template #label>
            <van-radio-group v-model="radio" disabled direction="horizontal" class="marginleft100 marginright100 radiobetween">
              <van-radio name="1">3次（系统默认次数）</van-radio>
            </van-radio-group>
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <span class="custom-title">每个微信号可为同一个选手投几票</span>
          </template>
          <template #label>
            <van-radio-group v-model="radio" disabled direction="horizontal" class="marginleft100 marginright100 radiobetween">
              <van-radio name="1">3次（系统默认次数）/或者不做限制</van-radio>
            </van-radio-group>
          </template>
        </van-cell>
      </van-tab>

    </van-tabs>

    <van-popup v-model="showPicker" round position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        title="选择完整时间"
        @cancel="cancelfirsttime"
        @confirm="confirmtime"
      />
    </van-popup>

  </div>
</template>

<script>
import { parseTime } from '../../utils/timeformat'
import { getQiniuToken } from '@/api/upload'
import { mapGetters, mapState } from 'vuex'
import { posteditvotemanage, getvotemanagedetail, postvotemanage } from '@/api/votemange'

export default {
  data() {
    return {
      showPicker: false,
      formdata: {
        launch_org: '',
        title: '',
        signup_start: '',
        signup_end: '',
        start_date: '',
        end_date: '',
        rule: '',
        award_design: '',
        banner: [],
        vote_config: {
          type: 2,
          cycle: 2,
          day_vote: 3,
          one_vote: 3
        }
      },
      show_pic: [],
      currentDate: new Date(),
      choosenumber: '',
      radio: '1',
      openid: '',
      editor: false,
      id: ''
    }
  },
  computed: {
    ...mapGetters(['baseSrc']),
    ...mapState('upload', ['upload_token', 'tokenTime'])
  },
  mounted() {
    this.openid = localStorage.getItem('openid')
    if (this.$route.params.id) {
      this.editor = true
      this.detailpage(this.$route.params.id)
      // const data = JSON.parse(this.$route.params.id)
      // console.log(data)
      // const { launch_org, title, sign_up_end, sign_up_start, start_date, end_date, rule, award_design, banner, id } = data
      // this.id = id
      // const banpic = banner.split(',')
      // const newbanner = []
      // banpic.forEach(item => {
      //   newbanner.push({ url: this.baseSrc + item, isImage: true })
      // })
      // this.formdata = {
      //   launch_org,
      //   title,
      //   signup_start: sign_up_start,
      //   signup_end: sign_up_end,
      //   start_date,
      //   end_date,
      //   rule,
      //   award_design,
      //   banner: newbanner,
      //   vote_config: {
      //     type: 2,
      //     cycle: 2,
      //     day_vote: 3,
      //     one_vote: 3
      //   }
      // }
      // console.log(this.formdata)
    }
  },
  methods: {
    async detailpage(newid) {
      const res = await getvotemanagedetail(newid, this.openid)
      const { launch_org, title, sign_up_end, sign_up_start, start_date, end_date, rule, award_design, banner } = res.data.items
      this.id = newid
      const banpic = banner.split(',')
      const newbanner = []
      banpic.forEach(item => {
        newbanner.push({ url: this.baseSrc + item, isImage: true })
      })
      this.formdata = {
        launch_org,
        title,
        signup_start: sign_up_start,
        signup_end: sign_up_end,
        start_date,
        end_date,
        rule,
        award_design,
        banner: newbanner,
        vote_config: {
          type: 2,
          cycle: 2,
          day_vote: 3,
          one_vote: 3
        }
      }
    },
    onClick(name, title) {
    },
    choosefirst(type) {
      this.choosenumber = type
      this.showPicker = true
    },
    cancelfirsttime() {
      this.showPicker = false
    },
    confirmtime(e) {
      const type = this.choosenumber
      const time = parseTime(e, '{y}-{m}-{d} {h}:{i}:{s}')
      if (type === 'firststart') {
        this.formdata.signup_start = time
      }
      if (type === 'firstend') {
        this.formdata.signup_end = time
      }
      if (type === 'secondstart') {
        this.formdata.start_date = time
      }
      if (type === 'secondend') {
        this.formdata.end_date = time
      }
      this.showPicker = false
    },
    async submit() {
      const { award_design, end_date, launch_org, rule, signup_end, signup_start, start_date, title, banner, vote_config } = this.formdata
      if (award_design === '' || end_date === '' || launch_org === '' || rule === '' || signup_end === '' ||
      signup_start === '' || start_date === '' || title === '' || banner.length === 0) {
        this.$toast.fail('信息填写不完整')
      } else {
        if (!this.editor) {
          const newbanner = []
          this.formdata.banner.forEach(item => {
            newbanner.push(item.picimg)
          })

          const data = {
            launch_org,
            title,
            signup_start,
            signup_end,
            start_date,
            end_date,
            rule,
            award_design,
            banner: newbanner.join(','),
            vote_config,
            open_id: this.openid
          }

          const res = await postvotemanage(data)
          if (res.code === 0) {
            this.$toast.success('上传成功')
            this.$router.go(-1)
          }
        } else {
          console.log('sasass', this.formdata)

          const newbanner = []
          this.formdata.banner.forEach(item => {
            if (item.picimg) {
              newbanner.push(item.picimg)
            }
            if (item.url) {
              newbanner.push(item.url.replace(this.baseSrc, ''))
            }
          })
          const data = {
            launch_org,
            title,
            signup_start,
            signup_end,
            start_date,
            end_date,
            rule,
            award_design,
            banner: newbanner.join(','),
            vote_config,
            open_id: this.openid
          }

          const res = await posteditvotemanage(this.id, data)
          if (res.code === 0) {
            this.$toast.success('编辑成功')
            this.$router.go(-1)
          }
        }
      }
      // this.$dialog.alert({
      //   title: '提示',
      //   message: '是否确定当前操作？',
      //   showCancelButton: true
      // }).then(() => {
      //   // on close
      // })
    },
    async afterRead(file) {
      console.log(file)
      file.status = 'uploading'
      file.message = '上传中...'
      const curTime = new Date().getTime()
      // 无upload_token或者超时则重新获取token  后端设置超时时间为 一小时
      if (!this.upload_token || !(curTime - this.tokenTime < 3500000)) {
        const { data } = await getQiniuToken()

        this.$store.commit('upload/SET_UPLOAD_TOKEN', data)
        this.$store.commit('upload/SET_UPLOAD_TOKEN_TIME', curTime)
      }

      const res = await this.$store.dispatch('upload/updateFile', file)
      // console.log(res, 'store返回的数据')
      if (res.code === 0) {
        file.status = 'success'
        file.picimg = res.data
      }
    }
  }
}
</script>

<style scoped>
.showpic_text{
  color: #646566;
  font-size: 14px;
  padding: 10px 16px;
}
.picpadding{
  padding: 10px 16px;
}
.upbuttom{
  margin: 50px;
}
.radiobetween{
  display: flex;
  justify-content: space-between
}
.marginleft100{
  margin-left:70px;
}
.marginleft50{
  margin-left:20px;
}
.marginright100{
  margin-right:40px;
}
.margintop{
  margin-top: 20px;
}
</style>
